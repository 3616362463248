import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'rectangular-add',
    templateUrl: './rectangular-add.component.html',
    styleUrls: ['./rectangular-add.component.scss']
})
export class RectangularAddComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}

<div class="banner-element">
    <div class="banner-element_text">
        <h3 class="title">
            {{'BANNER.youHave' | translate}} <strong>{{title.count}} {{title.type}}</strong>
        </h3>
        <p class="description">
            {{'BANNER.tenant' | translate}}
            <span class="highlight">{{tenant}}</span>
            {{actionType}}
            <span class="highlight">{{action}}</span>
            {{'BANNER.inApps' | translate}}
            <span class="highlight">{{address}}</span>
            {{'BANNER.on' | translate}}
            <span class="highlight">{{formatDate(date)}}</span>
        </p>
    </div>
    <button mat-button class="sr-kit_button" data-color="primary" *ngIf="buttonText" (click)="buttonClick.emit()">
        {{buttonText}}
    </button>
</div>

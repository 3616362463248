import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {parseJwt} from '../utils';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {LoaderService} from './loader.service';

type TokenStatus = 'ready' | 'pending' | 'empty';
type AuthForm = { email: string, password: string };

export interface UserModel {
    _id: string,
    email: string,
    firstName: string,
    lastName: string,
    image?: string,
    role: string,
    owner_id?: string,
    agency_id?: string
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private readonly tokenRefreshInterval = 1500;

    public tokenUpdated: Subject<any> = new Subject<any>();
    public tokenStatusUpdated: Subject<any> = new Subject<any>();
    private _token: string | undefined;
    private _tokenStatus: TokenStatus = 'empty';
    private tokenUpdateTimestamp: number | undefined;

    private _user: UserModel | undefined;
    public userUpdated: Subject<any> = new Subject<any>();
    public userPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get user(): UserModel | undefined {
        return this._user;
    }

    set user(value: UserModel | undefined) {
        this._user = value;
        this.userUpdated.next(value);
        console.log('USER:', value)
    }

    get token(): string | undefined {
        return this._token;
    }

    set token(value: string | undefined) {
        this._token = value;
        this.tokenStatus = value ? 'ready' : 'empty';
        this.tokenUpdateTimestamp = value ? Date.now() : undefined;
        this.tokenUpdated.next(value);
    }

    get tokenStatus(): TokenStatus {
        return this._tokenStatus;
    }

    set tokenStatus(value: TokenStatus) {
        this._tokenStatus = value;
        this.tokenStatusUpdated.next(value);
    }

    constructor(
        private http: HttpClient,
        private router: Router,
        private loadingService: LoaderService
    ) {
        let storedData = localStorage.getItem('userInfo');
        if (storedData) {
            const parsed = JSON.parse(storedData);
            this.user = parsed.user;
            this.token = parsed.token;
        }
        // @ts-ignore
        window['userId'] = this.user?._id;
    }

    public refreshToken() {
        this.tokenStatus = 'pending';
        this.http.post(`${environment.api}auth/refresh`, {}, {withCredentials: true})
            .subscribe({
                next: (response: any) => {
                    if (response && response.model && response.model.accessToken) {
                        const {_id, firstName, lastName, role, email} = response.model;
                        this.user = {_id, firstName, lastName, role, email};
                        this.token = response.model.accessToken;
                        localStorage.setItem('userInfo', JSON.stringify({
                            user: this.user,
                            token: this.token
                        }))
                    } else {
                        this.logout();
                    }
                },
                error: (err) => {
                    console.error(err);
                    this.logout();
                }
            })
    }

    public login(form: AuthForm) {
        if (!form.email || !form.password) throw Error('Invalid auth credentials');
        this.userPending.next(true);
        this.http.post(`${environment.api}auth/authorization/admin`, form).subscribe({
            next: (response: any) => {
                if (response && response.credentials && response.tokens) {
                    this.user = response.credentials;
                    this.token = response.tokens.accessToken;
                    if (this.user?.role === 'TechnicalProvider') {
                        this.router.navigate(['/provider']).then();
                    } else {
                        this.router.navigate(['/dashboard']).then();
                    }
                    localStorage.setItem('userInfo', JSON.stringify({
                        user: this.user,
                        token: this.token
                    }))
                    this.loadingService.clear();
                } else {
                    throw Error('Auth response invalid');
                }
                this.userPending.next(false);
            },
            error: (error: any) => {
                console.error(error?.error);
                this.userPending.next(false);
            }
        })
    }

    public logout() {
        this.user = undefined;
        this.token = undefined;
        this.tokenStatus = 'empty';
        localStorage.removeItem('userInfo');
        this.router.navigate(['/auth']).then();
        this.loadingService.clear();
    }

    public isTokenRefreshAvailable(): boolean {
        if (!this.tokenUpdateTimestamp || this.tokenStatus !== 'pending') return true;
        return Date.now() - this.tokenUpdateTimestamp > this.tokenRefreshInterval;
    }

    public isTokenAlive(): boolean {
        if (!this.token) return false;
        try {
            const parsedToken = parseJwt(this.token);
            if (!parsedToken || !parsedToken.exp) return false;
            return parsedToken.exp * 1000 - new Date().getTime() >= 30000;
        } catch (e) {
            console.error('INNER', e);
            return false;
        }
    }
}

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {

    constructor(
    ) {
    }

    transform(value: any): unknown {
        return value ? value[0] : value;
    }

}

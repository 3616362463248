import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private translateService: TranslateService,
        private iconService: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {
    }

    private safeURL = this.sanitizer.bypassSecurityTrustResourceUrl;

    ngOnInit(): void {
        this.initLocalization()
        this.initIconSet()
    }

    private initLocalization() {
        this.translateService.addLangs(['en', 'fr', 'de'])
        this.translateService.setDefaultLang('fr')
        const currentLang = localStorage.getItem('lang');
        if (currentLang) {
            this.translateService.use(currentLang)
        } else {
            this.translateService.use('fr')
        }
    }

    private initIconSet() {
        this.iconService.addSvgIcon(
            'pass_eye',
            this.safeURL('assets/icons/pass_eye.svg')
        )
        this.iconService.addSvgIcon(
            'app_store',
            this.safeURL('assets/icons/app_store.svg')
        )
        this.iconService.addSvgIcon(
            'play_market',
            this.safeURL('assets/icons/play_market.svg')
        )
        this.iconService.addSvgIcon(
            'service_interventions',
            this.safeURL('assets/icons/services/service_interventions.svg')
        )
        this.iconService.addSvgIcon(
            'service_polls',
            this.safeURL('assets/icons/services/service_polls.svg')
        )
        this.iconService.addSvgIcon(
            'service_points',
            this.safeURL('assets/icons/services/service_points.svg')
        )
        this.iconService.addSvgIcon(
            'service_tenants',
            this.safeURL('assets/icons/services/service_tenants.svg')
        )
        this.iconService.addSvgIcon(
            'service_phone_book',
            this.safeURL('assets/icons/services/service_phone_book.svg')
        )
        this.iconService.addSvgIcon(
            'service_operations',
            this.safeURL('assets/icons/services/service_operations.svg')
        )
        this.iconService.addSvgIcon(
            'service_bails',
            this.safeURL('assets/icons/services/service_bails.svg')
        )
        this.iconService.addSvgIcon(
            'service_collaborative',
            this.safeURL('assets/icons/services/service_collaborative.svg')
        )
        this.iconService.addSvgIcon(
            'service_advertisement',
            this.safeURL('assets/icons/services/service_advertisement.svg')
        )
        this.iconService.addSvgIcon(
            'service_accounts',
            this.safeURL('assets/icons/services/service_accounts.svg')
        )
        this.iconService.addSvgIcon(
            'dashboard',
            this.safeURL('assets/icons/services/dashboard.svg')
        )
        // rectangular options
        this.iconService.addSvgIcon(
            'option-edit',
            this.safeURL('assets/icons/rectangular-options/edit.svg')
        )
        this.iconService.addSvgIcon(
            'option-cancel',
            this.safeURL('assets/icons/rectangular-options/cancel.svg')
        )
        this.iconService.addSvgIcon(
            'option-delete',
            this.safeURL('assets/icons/rectangular-options/delete.svg')
        )
        this.iconService.addSvgIcon(
            'option-chart',
            this.safeURL('assets/icons/rectangular-options/bar-chart.svg')
        )
        this.iconService.addSvgIcon(
            'option-duplicate',
            this.safeURL('assets/icons/rectangular-options/duplicate.svg')
        )
        this.iconService.addSvgIcon(
            'option-look',
            this.safeURL('assets/icons/rectangular-options/look.svg')
        )
        this.iconService.addSvgIcon(
            'option-play',
            this.safeURL('assets/icons/rectangular-options/play.svg')
        )
        // categories
        this.iconService.addSvgIcon(
            'category-carpentry',
            this.safeURL('assets/icons/categories/carpentry.svg')
        )
        this.iconService.addSvgIcon(
            'category-garden',
            this.safeURL('assets/icons/categories/garden.svg')
        )
        this.iconService.addSvgIcon(
            'category-heat',
            this.safeURL('assets/icons/categories/heat.svg')
        )
        this.iconService.addSvgIcon(
            'category-intercom',
            this.safeURL('assets/icons/categories/intercom.svg')
        )
        this.iconService.addSvgIcon(
            'category-ironwork',
            this.safeURL('assets/icons/categories/ironwork.svg')
        )
        this.iconService.addSvgIcon(
            'category-lifebuoy',
            this.safeURL('assets/icons/categories/lifebuoy.svg')
        )
        this.iconService.addSvgIcon(
            'category-lift',
            this.safeURL('assets/icons/categories/lift.svg')
        )
        this.iconService.addSvgIcon(
            'category-masonry',
            this.safeURL('assets/icons/categories/masonry.svg')
        )
        this.iconService.addSvgIcon(
            'category-roof',
            this.safeURL('assets/icons/categories/roof.svg')
        )
        this.iconService.addSvgIcon(
            'category-window',
            this.safeURL('assets/icons/categories/window.svg')
        )
        this.iconService.addSvgIcon(
            'category-electricity',
            this.safeURL('assets/icons/categories/electricity.svg')
        )
        this.iconService.addSvgIcon(
            'category-locksmith',
            this.safeURL('assets/icons/categories/locksmith.svg')
        )
        this.iconService.addSvgIcon(
            'category-maintain',
            this.safeURL('assets/icons/categories/maintain.svg')
        )
        this.iconService.addSvgIcon(
            'category-plumbing',
            this.safeURL('assets/icons/categories/plumbing.svg')
        )
        this.iconService.addSvgIcon(
            'category-sanitation',
            this.safeURL('assets/icons/categories/sanitation.svg')
        )
        // polls menu
        this.iconService.addSvgIcon(
            'polls-text',
            this.safeURL('assets/icons/polls-menu/free-text.svg')
        )
        this.iconService.addSvgIcon(
            'polls-single',
            this.safeURL('assets/icons/polls-menu/single-select.svg')
        )
        this.iconService.addSvgIcon(
            'polls-multiple',
            this.safeURL('assets/icons/polls-menu/multiple-select.svg')
        )
        this.iconService.addSvgIcon(
            'polls-image',
            this.safeURL('assets/icons/polls-menu/image-select.svg')
        )
        this.iconService.addSvgIcon(
            'polls-eval',
            this.safeURL('assets/icons/polls-menu/evaluation-select.svg')
        )
        // Other
        this.iconService.addSvgIcon(
            'export-pdf',
            this.safeURL('assets/icons/export_pdf.svg')
        )
        this.iconService.addSvgIcon(
            'export-xls',
            this.safeURL('assets/icons/export_xls.svg')
        )
    }
}

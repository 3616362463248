import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoaderService {
    public readonly changes = new Subject();
    private loadingQueue: any[] = [];

    public add() {
        this.loadingQueue.push('loaderItem');
        this.changes.next(true);
    }

    public isLoading() {
        return !!this.loadingQueue.length;
    }

    public remove() {
        this.loadingQueue.pop();
        this.changes.next(true);
    }

    public clear() {
        this.loadingQueue = [];
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'sr-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {formatDate} from '../../utils';

@Component({
    selector: 'rectangular-item',
    templateUrl: './rectangular.component.html',
    styleUrls: ['./rectangular.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RectangularComponent implements OnInit {

    @Input()
    title: string = '';
    @Input()
    date: string = '';
    @Input()
    description: string = '';
    @Input()
    type: 'statistics' | 'action' | undefined;
    @Input()
    actionText: string = '';
    @Input()
    actionLink: string | undefined;
    @Output()
    actionClick: EventEmitter<any> = new EventEmitter<any>();
    @Input()
    isActive: boolean = true;
    @Input()
    options: any[] | undefined;
    @Input()
    value: any;

    public formatDate = formatDate;

    constructor() {
    }

    ngOnInit(): void {
    }

    debug(data: any) {
        console.log(data)
    }

}

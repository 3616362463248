import {environment} from '../environments/environment';

export function formatDate(date: any, timeIncluded = false) {
    if (!date) return 'INVALID DATE';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth();
    const day = d.getDate();
    if (!timeIncluded) {
        return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${month + 1}/${year}`;
    } else {
        const hour = d.getHours();
        const minutes = d.getMinutes();
        return `${day < 10 ? '0' : ''}${day}/${month + 1 < 10 ? '0' : ''}${month + 1}/${year}
                ${hour < 10 ? '0' : ''}${hour}:${minutes < 10 ? '0' : ''}${minutes}`;
    }
}

export function parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function isCacheInvalid(timestamp: number | undefined, cacheTime: number = 0) {
    return !timestamp || Date.now() - timestamp > cacheTime;
}

export function getRenderedImage(name: string) {
    if (!name) return '';
    return `${environment.api}/files/render/image/${name}`
}

export function parseDescription (item: any): any {
    if (!item || typeof item !== 'string') return ''
    const result = item.split('\n')
    const description = result.splice(0, 1)
    const chat: any = []
    const regex = /[a-h0-9]{24}/gm
    result.forEach((item) => {
        // isAdmin:true&message:privet&date:1649248620092
        const parseMessage = item.split('&')
        const finalMsg = {
            isAdmin: false,
            message: '',
            date: new Date(),
            isImage: false
        }
        parseMessage.forEach(msg => {
            if (msg.includes('isAdmin', 0)) {
                finalMsg.isAdmin = msg.includes('true')
            } else if (msg.includes('message', 0)) {
                finalMsg.message = msg.replace('message:', '')
                const images = msg.match(regex)
                if (images && images.length === 1) {
                    finalMsg.isImage = true
                }
            } else if (msg.includes('date', 0)) {
                finalMsg.date = new Date(Number(msg.replace('date:', '')))
            }
        })
        chat.push(finalMsg)
    })
    return { description: description[0], chat }
}

export function getPreparedFilters(filters: any) {
    const result: any = {};
    filters?.forEach((entry: any) => {
        if (!entry.value || (entry.value.constructor === Array && !entry.value.length)) return;
        switch (entry.type) {
            case 'date':
                result[entry.fieldName] = {
                    $gte: new Date(entry.value).getTime(),
                    $lt: new Date(entry.value).getTime() + 86400000
                };
                break;
            case 'dateRange':
                if (!entry.value[0] && !entry.value[1]) return;
                const data: any = {};
                if (entry.value[0]) {
                    data.$gte = new Date(entry.value[0]).getTime();
                }
                if (entry.value[1]) {
                    data.$lt = new Date(entry.value[1]).getTime() + 86400000;
                }
                result[entry.fieldName] = data;
                break;
            case 'select':
                if (!entry.multiple) {
                    result[entry.fieldName] = {
                        $in: [entry.value]
                    }
                } else {
                    result[entry.fieldName] = {
                        $in: entry.value
                    }
                }
                break;
            case 'string':
                result[entry.fieldName] = {
                    $in: [entry.value]
                };
                break;
            case 'rating':
                result[entry.fieldName] = entry.value;
                break;
        }
    })
    return result;
}

export function generatePassword() {
    const charactersArray = 'a-z,A-Z,0-9,#'.split(',')
    let CharacterSet = ''
    let password = ''
    if (charactersArray.indexOf('a-z') >= 0) {
        CharacterSet += 'abcdefghijklmnopqrstuvwxyz'
    }
    if (charactersArray.indexOf('A-Z') >= 0) {
        CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    }
    if (charactersArray.indexOf('0-9') >= 0) {
        CharacterSet += '0123456789'
    }
    if (charactersArray.indexOf('#') >= 0) {
        CharacterSet += '![]{}()%&*$#^<>~@|'
    }
    for (let i = 0; i < 15; i++) {
        password += CharacterSet.charAt(Math.floor(Math.random() * CharacterSet.length))
    }
    return password;
}

export function getFullAddress(data: any) {
    return data?.displayID || data?.name;
}

export function translateCategory(category: any, currentLang: string) {
    if (!category) {
        return 'ERROR'
    }
    if (category.data && category.data[currentLang]) {
        return category.data[currentLang]
    }
    return category.name
}

export function translateCategoriesArray(array: any[], currentLang: string) {
    if (!array?.length) {
        return 'ERROR'
    }
    let res: string[] = []
    array.forEach(category => {
        res.push(translateCategory(category, currentLang))
    })
    return res.join(', ')
}

export interface TableConfig {
    paging: {
        page: number
        limit: number
    }
    filters: any
    headers: {
        key: string
        title: string
        sort?: boolean
        cell?: any
        options?: boolean
        _sortValue?: number
    }[]
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

interface DashboardServiceItem {
    key: string,
    icon: string,
    badge?: boolean,
    active?: boolean,
    access?: string[]
    submenu?: {
        key: string,
        access?: string[]
    }[]
}

@Injectable()
export class DashboardService {

    private _navigationOpened = false;
    get navigationOpened(): boolean {
        return this._navigationOpened;
    }
    set navigationOpened(value: boolean) {
        this._navigationOpened = value;
        this.changesTrigger.next(true);
    }

    private _rightPanelOpened = false;
    get rightPanelOpened(): boolean {
        return this._rightPanelOpened;
    }
    set rightPanelOpened(value: boolean) {
        this._rightPanelOpened = value;
        this.changesTrigger.next(value);
        this.rightPanelObserver.next(value);
    }

    public rightPanelObserver = new Subject<any>();
    public changesTrigger = new Subject<any>();

    public services: DashboardServiceItem[] = [
        {
            key: 'interventions',
            icon: 'service_interventions',
            badge: true
        },
        {
            key: 'polls',
            icon: 'service_polls'
        },
        {
            key: 'tenants',
            icon: 'service_tenants'
        },
        {
            key: 'points',
            icon: 'service_points'
        },
        {
            key: 'management',
            icon: 'service_accounts',
            submenu: [
                {key: 'accounts', access: ['admin', 'familyOfficeAccount']},
                {key: 'config'},
                {key: 'settings', access: ['admin', 'familyOfficeAccount']},
                {key: 'bots', access: ['admin', 'familyOfficeAccount']}
            ]
        },
        {
            key: 'collaboration',
            icon: 'service_collaborative'
        },
        {
            key: 'providers',
            icon: 'service_phone_book',
            access: ['admin', 'familyOfficeAccount']
        },
        {
            key: 'advertisements',
            icon: 'service_advertisement'
        },
        {
            key: 'bails',
            icon: 'service_bails',
            access: ['admin', 'familyOfficeAccount']
        },
        {
            key: 'operations',
            icon: 'service_operations',
            access: ['admin', 'familyOfficeAccount']
        },
    ];

    public toggleNavigation() {
        this.navigationOpened = !this.navigationOpened;
    }

    public toggleRightPanel() {
        this.rightPanelOpened = !this.rightPanelOpened;
    }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'right-panel-item',
    templateUrl: './right-panel-item.component.html',
    styleUrls: ['./right-panel-item.component.scss']
})
export class RightPanelItemComponent implements OnInit {

    @Input()
    username: string = '';

    constructor() {
    }

    ngOnInit() {
    }

}

<div class="rectangular-element" [class.active]="isActive">
    <button class="options-icon" mat-icon-button *ngIf="options && options.length" [matMenuTriggerFor]="optionsMenu">
        <mat-icon>more_vert</mat-icon>
    </button>
    <p class="title" [title]="title">{{title}}</p>
    <p class="date">{{date ? formatDate(date, true) : ''}}</p>
    <p class="description">{{description}}</p>
    <button
        mat-button
        class="sr-kit_button"
        *ngIf="type === 'statistics' && actionClick.observed"
        data-color="statistics"
        data-size="s"
        (click)="actionClick.emit(value)"
    >
        {{actionText}}
    </button>

    <button mat-button class="sr-kit_button"
            *ngIf="type === 'action' && actionLink"
            data-color="primary"
            data-size="s"
            [routerLink]="actionLink"
    >
        {{actionText}}
    </button>

    <button mat-button class="sr-kit_button"
            *ngIf="type === 'action' && actionClick.observed"
            data-color="primary"
            data-size="s"
            (click)="actionClick.emit(value)"
    >
        {{actionText}}
    </button>

    <mat-menu #optionsMenu="matMenu" class="rectangular-options-menu" xPosition="before">
        <ng-container *ngFor="let option of options">
            <button
                mat-menu-item
                (click)="option.cb(value)"
                [ngClass]="{'red': option.highlight}"
                *ngIf="!option.active && !option.inactive"
            >
                <mat-icon [svgIcon]="option.icon"></mat-icon>
                {{'MENU_OPTIONS.' + option.title | translate}}
            </button>
            <button
                mat-menu-item
                (click)="option.cb(value)"
                *ngIf="option.active && value.activated"
            >
                <mat-icon [svgIcon]="option.icon"></mat-icon>
                {{'MENU_OPTIONS.' + option.title | translate}}
            </button>
            <button
                mat-menu-item
                (click)="option.cb(value)"
                *ngIf="option.inactive && !value.activated"
            >
                <mat-icon [svgIcon]="option.icon"></mat-icon>
                {{'MENU_OPTIONS.' + option.title | translate}}
            </button>
        </ng-container>
    </mat-menu>
</div>

import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {translateCategory} from '../utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'dialogFilter',
    templateUrl: 'filter.dialog.html',
    styles: [`
        .dialog-wrapper {
            p {
                margin-top: 16px;
            }
            .btn-container {
                display: flex;
                flex-flow: row nowrap;
                gap: 16px;
                margin-top: 32px;
            }
        }
    `]
})
export class FilterDialog {
    constructor(
        public dialogRef: MatDialogRef<FilterDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translateService: TranslateService
    ) {
    }

    resetValue() {
        if (typeof this.data.value === 'object' && this.data.value.length) {
            this.data.value = [];
        } else {
            this.data.value = null;
        }
        this.dialogRef.close(true);
    }

    isAsyncList(list: any) {
        return list.constructor !== Array;
    }

    getCategoryName(category: any) {
        return translateCategory(category, this.translateService.currentLang)
    }
}

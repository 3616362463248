import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'banner-new',
    templateUrl: './banner-new.component.html',
    styleUrls: ['./banner-new.component.scss']
})
export class BannerNewComponent implements OnInit {

    @Output()
    buttonClick = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

}
